import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import SiteMeta from '../components/meta-data'
import Layout from '../components/layout'

class WorkPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
			visible:4,
		}

		this.loadMore = this.loadMore.bind(this)
    }

    loadMore(){
		this.setState((prevState) => {
			return {
				visible:prevState.visible + 3
			}
		})
	}
   
    render() {
        const works = get(this, 'props.data.allContentfulOurWork.edges')
        const metaData = get(this.props, 'data.site.siteMetadata.caseStudyPage')
        const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
        const data = {
            hero:'hero-work',
            size:'is-medium',
            isHidden: true,
            text: 'Our Work',
            servicesNav
          }
        return (
            <Layout location={this.props.location} data={data}>
                <SiteMeta metaData={metaData}/>
                <div className="work section">
                {
                    works.slice(0, this.state.visible).map(({node},index) => {
                        return(
                            <div className="section" key={index}>
                                <div className="container">
                                    <div className="columns is-centered">
                                        <div className="column is-9">
                                            <div className="box">
                                                <div className="columns" style={{alignItems:'center'}}>
                                                    <div className="column is-5 has-text-centered">
                                                        <h2 className="title is-size-5 is-capitalized">
                                                            industry: {node.industry}
                                                        </h2>
                                                        <h2 className="title is-size-5 is-capitalized">
                                                            services:
                                                        </h2>
                                                        <ul className="is-size-5">
                                                        {
                                                            node.services.map((service,index)=>{
                                                                return(
                                                                    <li key={index}>
                                                                        {service}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        </ul>
                                                    </div>
                                                    <div className="column is-7">
                                                        <figure className="image">
                                                            <img src={node.companyLogo.file.url} alt={node.companyName}/>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                { this.state.visible < works.length &&
                    <div className="columns is-centered">
                        <div className="column is-6">
                            <div className="has-text-centered">
                                <button className="button is-danger is-medium" type="button" onClick={this.loadMore}>Load More</button>
                            </div>
                        </div>	
                    </div>
                }
                </div>

            </Layout> 
        )

    }
}

export default WorkPage

export const pageQuery = graphql`
    query WorkQuery {
        site {
            siteMetadata {
              caseStudyPage {
                title
                description
                imageUrl
                siteUrl
              }
            }
         } 
        allContentfulOurWork(filter: {node_locale: {eq: "en-GB"}}, sort: {fields: [publishDate], order: DESC}) {
            edges {
              node {
                companyName  
                industry
                services
                companyLogo {
                  file {
                    url
                  }
                }
              }
            }
          }
          allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
            edges {
              node {
                title
                slug
              }
            }
          }  
    }

`